<script lang="ts">
	import { ProgressRadial } from '@skeletonlabs/skeleton';

	export let onClick = async () => {
	};

	let isLoading = false;

	async function onLoading() {
		isLoading = true;
		onClick().finally(() => {
			isLoading = false;
		});
		setTimeout(() => {
			isLoading = false;
		}, 2000);
	}
</script>

<button {...$$restProps} disabled={isLoading} on:click={onLoading} on:mouseenter on:mouseleave>
	{#if isLoading}
		<ProgressRadial width="w-6" />
	{:else}
		<slot />
	{/if}
</button>
